import React, { useEffect, useState } from "react";
import MatchesTab from "../../../components/sections/tabs/MatchesTab";
import { useNavigate, useLocation } from "react-router-dom";
import { classNames } from "../../../utils/classNames";
import { useSelector } from "react-redux";
import PlayerSettingsTab from "../../../components/sections/tabs/PlayerSettingsTab";
import PlayerEntriesTab from "../../../components/sections/tabs/PlayerEntriesTab";

export default function Profile() {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();


  const tabs = [
    {
      name: "Matches",
      href: "#matches",
      element: (key) => <MatchesTab key={key} />,
    },
    {
      name: "Entries",
      href: "#entries",
      element: (key) => <PlayerEntriesTab player={currentUser?.player} key={key} />
    },
    {
      name: "Settings",
      href: "#settings",
      element: (key) => <PlayerSettingsTab key={key} />,
    },
  ];


  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab.name);
    navigate(tab.href);
  };

  useEffect(() => {
    if (!location.hash) {
      setActiveTab(tabs[0].name);
    } else {
      const currentTab = tabs.find((tab) => tab.href === location.hash);
      if (currentTab) {
        setActiveTab(currentTab.name);
      }
    }
  }, [location.hash]);

  return (
        <>
          <div className="flex items-start gap-2 mt-5 mb-10">
            <div className=" px-3">
              {currentUser?.player?.id ? (
                <div className="flex items-center gap-3">
                  <div className="text-lg font-bold">
                    {currentUser?.player?.firstName},
                    {currentUser?.player?.lastName}
                  </div>
                  {currentUser?.player?.memberId ? (
                    <div className="flex items-center font-medium uppercase text-[10px] px-2 bg-blue-600 text-white rounded-sm">
                      {currentUser?.player?.memberId}
                    </div>
                  ) : (
                    <div className="flex items-center font-medium uppercase text-[10px] px-2 bg-yellow-400 text-white rounded-sm">
                      {currentUser?.player?.tempId}
                    </div>
                  )}
                </div>
              ) : (
                <button
                  onClick={() =>
                    handleActiveTabChange(
                      tabs.find((tab) => tab.href === "#settings")
                    )
                  }
                  className="text-xs font-medium text-white uppercase px-3 rounded-sm bg-blue-600"
                >
                  Connect Profile
                </button>
              )}
              <div className="flex items-center gap-1 mt-2">
                {currentUser?.email}
              </div>
            </div>
          </div>

          <div className="relative pb-5 border-b border-gray-200 sm:pb-0 px-3">
            <div className="mt-8 sm:mt-4">
              <div className="mt-8 sm:mt-4">
                <nav className="flex -mb-px space-x-4 sm:space-x-8">
                  {tabs.map((tab) => (
                    <button
                      onClick={() => handleActiveTabChange(tab)}
                      key={tab.name}
                      className={classNames(
                        tab.name === activeTab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-4 text-xs sm:text-sm font-medium capitalize"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="px-3">
            {tabs.map((tab, index) => {
              if (tab.name === activeTab) {
                let key = tab.name + "-" + index;
                return <div key={key}>{tab.element(key)}</div>;
              }
              return null;
            })}
          </div>
        </>
  );
}
