import React from "react";
import Papa from "papaparse";
import { useImportPlayerMutation } from "../redux/features/api/apiSlice";

export const CsvImporter = ({id}) => {
  const [importPlayers, { isLoading }] = useImportPlayerMutation();
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: function (results) {
          console.log(results);
          processCSVData(results.data);
        },
        error: function (error) {
          console.error(error);
        },
      });
    }
  };

  const playerTemplate = {
    "Member ID": "memberId",
    "Date of Birth": "dob",
    "First Name": "firstName",
    Gender: "sex",
    "Last Name": "lastName",
    "Last Played Date": "lastPalyedDate",
    "Expiration Date": "memberShipExpiryDate",
    Rating: "rating",
    State: "state",
    "USATT #": "memberShip",
    "Zip": "zip",
  };

  const processCSVData = (players) => {
    const normalizedPlayers = players.map((player) => {
      const normalizedPlayer = {};

      Object.keys(playerTemplate).forEach((templateKey) => {
        const normalizedKey = playerTemplate[templateKey];
        normalizedPlayer[normalizedKey] = player[templateKey] || null;
      });
      return normalizedPlayer;
    });

    importPlayers({ id, data: normalizedPlayers });
    console.log("Processed Data:", normalizedPlayers);
  };

  return (
    <div>
      <label
        htmlFor="import-player"
        className="inline-flex items-center px-1 sm:px-3 py-2 ml-3 text-xs sm:text-sm text-center font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Import Players
        <input
          type="file"
          id="import-player"
          accept=".csv"
          className="sr-only"
          onChange={handleFileSelect}
        />
      </label>
    </div>
  );
};
