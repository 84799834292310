import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const eventInitialState = {
  tournamentId: "",
  eventName: "",
  eventId: "",
  startDate: "",
  startTime: "",
  eventFee: 0,
  sex: "M",
  status: "draft",
  customSettings: {
    enableShowResults: true,
    usatt: false
  },
  entryRestrictions: {
    maxNoOfPlayers: 16,
    minDOB: "",
    maxDOB: "",
    minRating: 0,
    maxRating: 0,
  },
  specialVariations: {
    disability: false
  },
};

export const initialState = {
  events: [],
  queryEvents:[],
  event: {},
  players: [],
};

export const eventSlice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getAllEvents.matchFulfilled,
      (state, action) => {
        state.events = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getQueryEventsByTournamentId.matchFulfilled,
      (state, action) => {
        state.queryEvents = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getEventsByTournamentId.matchFulfilled,
      (state, action) => {
        state.events = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getPlayersByEventId.matchFulfilled,
      (state, action) => {
        state.players = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getEventById.matchFulfilled,
      (state, action) => {
        if (action.payload && action.payload.data) {
          state.event = action.payload.data;
        } else {
          console.error('Error: Payload data is undefined or malformed', action.payload);
        }
      }
    );
  },
});

export default eventSlice.reducer;
