import React, { useState } from "react";
import FormFieldError from "../../../components/commons/formFeildError..jsx";
import {
  useCreateProfileMutation,
  useUpdateProfileMutation,
} from "../../../redux/features/api/apiSlice.js";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const PlayerSettingsTab = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [createProfile] = useCreateProfileMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...currentUser?.player,
      email: currentUser?.email,
    },
  });
  const onSubmit = async(data) => {
    if(loading) return
    setLoading(true)
    try {
      if (currentUser?.player?.id) {
        const res = await updateProfile({
          data,
          userId: currentUser.id,
        });
        if(!res.data.data.error){
          toast.success('Profile Updated Successfully')
        }
      } else {
        const res = await createProfile({
          data,
          userId: currentUser.id,
        });
        if(!res.data.data.error){
          toast.success('Profile Created Successfully')
        }
      }
    } catch (error) {
      toast.error(error)
    }finally{
      setLoading(false)
    }
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-3 sm:p-8 space-y-4 max-w-2xl self-center mx-auto">
            <div className="flex flex-row items-center justify-end gap-3 mt-5 md:mt-0">
              <button
                type="submit"
                className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading ? 'loading..' : 'Submit'}
              </button>
            </div>

            <div className="border-2 border-gray-200 rounded-xl p-1 sm:p-3 grid grid-cols-2">
              <div className="grid items-center justify-between col-span-9 md:col-span-4 grid-cols-12 gap-4 sm:pr-3 pb-10">
                <div className=" col-span-12 h-6"></div>
                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="firstName"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    First Name
                  </label>
                  <input
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="firstName"
                    {...register("firstName", {
                      required: "First Name is required",
                    })}
                  />
                  <FormFieldError errors={errors} field={"firstName"} />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="lastName"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    {...register("lastName", {
                      required: "Last Name is required",
                    })}
                  />

                  <FormFieldError errors={errors} field={"lastName"} />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="sex"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Gender
                  </label>
                  <select
                    className="w-full col-span-8  border-2 bg-transparent py-2.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="sex"
                    {...register("sex", { required: "Gender is required" })}
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                  <FormFieldError errors={errors} field={"sex"} />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="dob"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    DOB
                  </label>
                  <input
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="dob"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    {...register("dob", {
                      required: " D.O.B is required",
                    })}
                  />
                  <FormFieldError
                    errors={errors?.entryRestrictions}
                    field={"dob"}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="homeClub"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Home Club
                  </label>
                  <input
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="homeClub"
                    type="text"
                    {...register("homeClub")}
                  />
                  <FormFieldError errors={errors} field={"homeClub"} />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="phone"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone
                  </label>
                  <input
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="phone"
                    type="tel"
                    {...register("phone")}
                  />
                  <FormFieldError errors={errors} field={"phone"} />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="email"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    disabled
                    className="w-full col-span-8  border-2 py-1.5 p-2 bg-gray-200 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="email"
                    type="email"
                    {...register("email")}
                  />
                  <FormFieldError errors={errors} field={"email"} />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="rating"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Rating
                  </label>
                  <input
                    className="w-full col-span-8 bg-gray-200  border-2 py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    disabled
                    id="rating"
                    type="number"
                    {...register("rating")}
                  />
                  <FormFieldError errors={errors} field={"rating"} />
                </div>
                <div className=" w-full col-span-12 grid grid-cols-3 mt-5">
                  <div className="col-span-1"></div>
                  <div className="col-span-1 ">
                    <label
                      htmlFor="pin"
                      className="block text-center col-span-4 text-sm font-medium leading-6 text-gray-900"
                    >
                      PUBLIC PIN
                    </label>
                    <input
                      className="w-full bg-gray-100  border-2 py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                      placeholder="set the pin to team members to add into events"
                      id="pin"
                      minLength={4}
                      maxLength={4}
                      type="text"
                      {...register("pin")}
                    />
                    <FormFieldError errors={errors} field={"pin"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PlayerSettingsTab;
