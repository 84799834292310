import React from "react";
import { useForm, Controller } from "react-hook-form";
import FormFieldError from "../../../components/commons/formFeildError.";
import { Select } from "../../../components/commons/AsyncSelect";
import { teamInitialState } from "../../../redux/features/teamSlice";

const AddTeamForm = ({ handleCloseForm }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: teamInitialState,
  });

  const options = [];

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-8 space-y-4">
        <div className="flex flex-row items-center justify-between">
          <div>
            <h1 className="text-lg font-semibold text-indigo-600 ">
              Add New Team
            </h1>
            <p className="text-sm text-gray-600 ">
              Please fill in the form below to add a new team
            </p>
          </div>
          <div className="flex flex-row items-center justify-between gap-3">
            <button
              type="button"
              onClick={handleCloseForm}
              className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-black rounded-md shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-3 py-2 text-sm font-semibold text-indigo-600 border-2 border-indigo-600 rounded-md shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save as draft
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Publish
            </button>
          </div>
        </div>

        <div className="grid items-center justify-between w-full grid-cols-12 gap-4">
          {/* <div className="flex flex-col items-start justify-start w-full col-span-12">
            <Controller
              control={control}
              name="tournamentId"
              render={({ field }) => (
                <Select
                  config={{
                    label: "Tournament",
                    placeholder: "Select Tournament...",
                    options: options,
                  }}
                  field={field}
                />
              )}
            />
          </div> */}

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="teamName"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Team Name
            </label>
            <input
              className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
              id="teamName"
              {...register("teamName", {
                required: "team name is required",
              })}
            />
            <FormFieldError errors={errors} field={"teamName"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="teamHomeClub"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Home Club
            </label>
            <input
              id="teamHomeClub"
              className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
              {...register("teamHomeClub", {
                required: "team home club is required",
              })}
            />

            <FormFieldError errors={errors} field={"teamHomeClub"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="teamCity"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Team City
            </label>
            <input
              className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
              id="teamCity"
              type="text"
              {...register("startDate", { required: "team City is required" })}
            />
            <FormFieldError errors={errors} field={"teamCity"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="teamState"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Team State
            </label>
            <input
              className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
              id="teamState"
              type="text"
              {...register("teamState", { required: "team State is required" })}
            />
            <FormFieldError errors={errors} field={"teamState"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="teamCountry"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Team Country
            </label>
            <input
              className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
              id="teamCountry"
              type="text"
              {...register("teamCountry", {
                required: "team Country is required",
              })}
            />
            <FormFieldError errors={errors} field={"teamCountry"} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddTeamForm;
