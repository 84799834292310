import React, { useState } from "react";
import DrawRoundRobbin from "../draws/drawRoundRobbin";
import DrawSingleElimination from "../draws/drawSingleElimination";

const draws = [
  {
    drawId: "032032032490ds9d0f21",
    drawsFormat: "round-robin",
    drawName: "U1900 RR",
    startDate: new Date(),
    startTime: "12:00:00",
    pointsPerGame: 0,
    gamesPerMatch: 0,
    scheduleMatches: false,
  },
  {
    drawId: "032032032490ds9d032323",
    drawsFormat: "single-elimination",
    drawName: "U1900 SE",
    startDate: new Date(),
    startTime: "12:00:00",
    pointsPerGame: 0,
    gamesPerMatch: 0,
    scheduleMatches: false,
  },
];

const DrawsTab = () => {
  const [selectedDraw, setSelectedDraw] = useState(0);
  const [drawFormat, setDrawFormat] = useState(draws[0].drawsFormat);

  return (
    <div className="flex flex-col items-start justify-start w-full mt-8">
      <div className="flex self-center gap-3 ">
        {
          draws?.map((draw, i)=>(
            <button key={i} onClick={()=>{setDrawFormat(draw.drawsFormat); setSelectedDraw(i)}} className={`px-5 py-2 bg-white rounded-full shadow hover:shadow-lg border border-gray-400 hover:border-gray-600 ${ i === selectedDraw ? 'border-2 border-gray-700' : ''}`}>
              <div className="text-xs sm:text-sm">{draw.drawName}</div>
            </button>
          ))
        }
      </div>
      <div className="flex flex-row items-stretch justify-start w-full min-h-full gap-4 mt-5">
        <div className="flex flex-row items-center justify-start flex-1 h-full gap-4 overflow-x-auto">
          {drawFormat === "round-robin" ? (
            <DrawRoundRobbin />
          ) : (
            <DrawSingleElimination />
          )}
        </div>
      </div>
    </div>
  );
};

export default DrawsTab;
