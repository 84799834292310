import React from "react";
import GroupCard from "../../card/Group";

const DrawRoundRobbin = () => {
  return (
    <div className="grid w-full grid-col-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {[1, 2, 4, 5, 6, 7].map((group, i) => (
        <GroupCard key={i} data={group}/>
      ))}
    </div>
  );
};

export default DrawRoundRobbin;
