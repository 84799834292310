import { TrophyIcon } from "@heroicons/react/16/solid";
import React from "react";


const Opponent = ({ opponent, index }) => {
  return (
    <div className="flex flex-row items-center justify-start gap-4 px-2 py-2 border">
      {/* group */}
      <div
        className={`w-6 h-6 text-center text-white ${
          index == 0 ? "bg-blue-600" : "bg-rose-600"
        } `}
      >
        {opponent.group}
      </div>
      <div className="flex flex-col flex-1 ">
        <div>
          <span className="text-xs sm:text-sm font-bold">{opponent.rating}</span>
          <span>-</span>
          <span className="text-xs sm:text-sm">{opponent.location}</span>
        </div>
        <span>
          <span className="text-xs sm:text-sm font-bold">{opponent.name}</span>
        </span>
      </div>
      <div className="">
        {opponent.winner ? (
          <TrophyIcon className="w-6 h-6 text-yellow-700 " />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const ScoreBoard = ({ match }) => {
  return (
    <div className="flex flex-col items-start justify-start bg-white shadow-md text-xs sm:text-sm">
      {/* Match Info */}
      <div className="flex flex-row items-center justify-between w-full px-4 py-2 bg-primary">
        {/* match info */}
        <div className="flex flex-row items-center justify-start gap-2">
          <span className="text-xs sm:text-sm font-bold ">{match.eventName}</span>
          <span className="text-xs sm:text-sm ">{match.group}</span>
        </div>
        {/* table no */}
        <div>{match.table}</div>
      </div>
      {/* Players */}
      <div className="flex flex-row items-center justify-between w-full">
        {/* player 1 */}
        {match.apponents.map((apponent, index) => (
          <div className="flex-1" key={index}>
            <Opponent opponent={apponent} index={index} />
          </div>
        ))}
      </div>
      {/* Scoring */}
      <div className="grid w-full grid-cols-7">
        {/* opponent 1 */}
        <div className="flex flex-col items-center justify-start col-span-1">
          <div className="w-full py-1 text-center text-white bg-blue-600 border border-blue-600">
            A
          </div>
          <div className="w-full py-1 text-center text-white border border-rose-600 bg-rose-600">
            B
          </div>
        </div>

        {match.rounds.map((round, index) => (
          <div
            className="flex flex-col items-center justify-start col-span-1"
            key={index}
          >
            <div className="w-full py-1 text-center border">{round.A}</div>
            <div className="w-full py-1 text-center border">{round.B}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScoreBoard;
