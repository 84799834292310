import React, { useState } from "react";
import ScoreBoard from "../commons/ScoreBoard";
import { classNames } from "../../utils/classNames";

const opponents = [
  {
    rating: 132,
    group: "A",
    name: "Gorak, Daniel",
    losses: 2,
    wins: 1,
    matchPlyed: 2,
    location: "Finland",
  },
  {
    rating: 2443,
    group: "B",
    name: "Hermann Hills",
    losses: 0,
    wins: 3,
    matchPlyed: 3,
    location: "Germany",
  },
];

const matches = [
  {
    eventName: "Singles",
    group: "Group 1",
    table: 32,
    opponents: [
      {
        rating: 132,
        group: "A",
        name: "Gorak, Daniel",
        losses: 2,
        wins: 1,
        matchPlyed: 2,
        location: "Finland",
        winner: true,
      },
      {
        rating: 2443,
        group: "B",
        name: "Hermann Hills",
        losses: 0,
        wins: 3,
        matchPlyed: 3,
        location: "Germany",
        winner: true,
      },
    ],
    rounds: [
      {
        A: 13,
        B: 3,
      },
      {
        A: 8,
        B: 11,
      },
      {
        A: 10,
        B: 14,
      },
      {
        A: 12,
        B: 12,
      },
      {
        A: 14,
        B: 10,
      },
      {
        A: 11,
        B: 13,
      },
    ],
  },
  {
    eventName: "Singles PPD IK",
    group: "Group 1",
    table: 32,
    opponents: [
      {
        rating: 132,
        group: "A",
        name: "Gorak, Daniel",
        losses: 2,
        wins: 1,
        matchPlyed: 2,
        location: "Finland",
        winner: true,
      },
      {
        rating: 2443,
        group: "B",
        name: "Hermann Hills",
        losses: 0,
        wins: 3,
        matchPlyed: 3,
        location: "Germany",
        winner: true,
      },
    ],
    rounds: [
      {
        A: 13,
        B: 3,
      },
      {
        A: 8,
        B: 11,
      },
      {
        A: 10,
        B: 14,
      },
      {
        A: 12,
        B: 12,
      },
      {
        A: 14,
        B: 10,
      },
      {
        A: 11,
        B: 13,
      },
    ],
  },
  {
    eventName: "Single Ks (kdksd)",
    group: "Group 1",
    table: 32,

    opponents: [
      {
        rating: 132,
        group: "A",
        name: "Gorak, Daniel",
        losses: 2,
        wins: 1,
        matchPlyed: 2,
        location: "Finland",
        winner: true,
      },
      {
        rating: 2443,
        group: "B",
        name: "Hermann Hills",
        losses: 0,
        wins: 3,
        matchPlyed: 3,
        location: "Germany",
        winner: true,
      },
      {
        rating: 132,
        group: "C",
        name: "Gorak, Daniel",
        losses: 2,
        wins: 1,
        matchPlyed: 2,
        location: "Finland",
        winner: true,
      },
      {
        rating: 2443,
        group: "D",
        name: "Hermann Hills",
        losses: 0,
        wins: 3,
        matchPlyed: 3,
        location: "Germany",
        winner: true,
      },
    ],
    rounds: [
      {
        A: 13,
        B: 3,
      },
      {
        A: 8,
        B: 11,
      },
      {
        A: 10,
        B: 14,
      },
      {
        A: 12,
        B: 12,
      },
      {
        A: 14,
        B: 10,
      },
      {
        A: 11,
        B: 13,
      },
    ],
  },
];

const Stading = (match) => {
  return (
    <div className="px-4 py-2 space-y-4 overflow-y-scroll divide-y max-h-48">
      {matches.map((match, i) => {
        const { opponents } = match;

        return opponents.map((opponent, j) => {
          return (
            <div
              className="flex flex-row items-center justify-start gap-4"
              key={`player-${i}-${j}`}
            >
              {/* group */}
              <div className={`w-6 h-6 text-center text-white bg-black`}>
                {opponent.group}
              </div>
              <div className="flex flex-col flex-1 ">
                <div>
                  <span className="text-sm font-bold">{opponent.rating}</span>
                  <span>-</span>
                  <span className="text-sm">{opponent.location}</span>
                </div>
                <span>
                  <span className="text-sm font-bold">{opponent.name}</span>
                </span>
              </div>
              <div className="py-2 ">
                <div className="grid items-center justify-center grid-cols-3 grid-rows-2 gap-4 text-center">
                  <span className="flex flex-col items-center justify-center text-xs text-center">
                    W
                  </span>
                  <span className="flex flex-col items-center justify-center text-xs text-center">
                    L
                  </span>
                  <span className="flex flex-col items-center justify-center text-xs text-center">
                    P
                  </span>
                  <span className="flex flex-col items-center justify-center text-xs text-center">
                    {opponent.wins}
                  </span>
                  <span className="flex flex-col items-center justify-center text-xs text-center">
                    {opponent.losses}
                  </span>
                  <span className="flex flex-col items-center justify-center text-xs text-center">
                    {opponent.matchPlyed}
                  </span>
                </div>
              </div>
            </div>
          );
        });
      })}
    </div>
  );
};

const GroupCard = ({ group }) => {
  const tabs = [
    {
      name: "stadings",
      href: "#",
      element: () => <Stading />,
    },
    {
      name: "matches",
      href: "#",
      element: () => <ScoreBoard match={matches[0]} />,
    },
  ];

  const [activeTab, setActiveTab] = useState("stadings"); // stadings | matches
  const handleActiveTabChange = () => {
    console.log(activeTab);
    setActiveTab(activeTab === "stadings" ? "matches" : "stadings");
  };
  return (
    <div className="flex flex-col items-start justify-start overflow-hidden bg-white rounded-md h-max">
      <div className="flex flex-row items-center justify-between w-full px-4 py-2 text-white bg-black">
        <h2 className="font-bold ">Group 1</h2>
        <button>oo</button>
      </div>
      <div className="mt-2 ">
        <div className="">
          <nav className="flex ">
            {tabs.map((tab, i) => (
              <button
                onClick={handleActiveTabChange}
                key={i}
                className={classNames(
                  activeTab === tab.name
                    ? "text-gray-950 font-bold"
                    : "text-gray-500 font-normal",
                  "px-4 py-2 whitespace-nowrap  pb-4 text-sm font-medium capitalize"
                )}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="w-full">
        {tabs.map((tab, i) => {
          if (tab.name === activeTab) {
            return <div key={i}>{tab.element()}</div>;
          }
          return null; // Ensure a valid return for other cases
        })}
      </div>
    </div>
  );
};

export default GroupCard;
