import React from "react";
import { useForm } from "react-hook-form";
import { drawsInitialState } from "../../../redux/features/drawSlice";
import FormFieldError from "../../commons/formFeildError.";
import { useAddNewDrawMutation } from "../../../redux/features/api/apiSlice";
import { useParams } from "react-router-dom";

const Draws = () => {
  const [addNewDraw] = useAddNewDrawMutation();

  let { eventId } = useParams();

  drawsInitialState.eventId = eventId;

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: drawsInitialState,
  });

  const drawFormat = watch("drawsFormat");

  const onSubmit = (data) => {
    addNewDraw(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <span className=" text-rose-700">
        clear the robin settings if switch between
      </span>
      <div className="p-2 space-y-1">
        <div className="grid items-start justify-between w-full grid-cols-12 gap-2">
          <div className="flex flex-col items-start justify-start w-full col-span-12">
            <label
              htmlFor="eventId"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Event Id
            </label>
            <input
              className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              id="eventId"
              disabled
              {...register("eventId")}
            />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-12">
            <label
              htmlFor="drawsFormat"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Select Draw Format
            </label>
            <select
              className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              id="drawsFormat"
              {...register("drawsFormat", {
                required: "Draw format is required",
              })}
            >
              <option value="roundRobin">Round Robin</option>
              <option value="singleElimination">Single Elimination</option>
              <option value="noFormat">No Format (BETA)</option>
            </select>
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-12">
            <label
              htmlFor="drawName"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Draw Name
            </label>
            <input
              id="drawName"
              className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              {...register("drawName", {
                required: "Draw Name is required",
              })}
            />

            <FormFieldError errors={errors} field={"drawName"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-12">
            <label
              htmlFor="drawId"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Draw Id
            </label>
            <input
              className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              id="drawId"
              {...register("drawId", { required: "Draw Id is required" })}
            />

            <FormFieldError errors={errors} field={"drawId"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="startDate"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Date
            </label>
            <input
              className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              id="startDate"
              type="date"
              {...register("startDate", { required: "Start Date is required" })}
            />
            <FormFieldError errors={errors} field={"startDate"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="startTime"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Time
            </label>
            <input
              className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              id="startTime"
              type="time"
              {...register("startTime", {
                required: "time is required",
              })}
            />
            <FormFieldError errors={errors} field={"startTime"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="pointsPerGame"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Points Per Game
            </label>
            <input
              className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              id="pointsPerGame"
              type="number"
              {...register("pointsPerGame", {
                required: "Points Per Game is required",
              })}
            />
            <FormFieldError errors={errors} field={"pointsPerGame"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="gamesPerMatch"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Games Per Match
            </label>
            <input
              className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              id="gamesPerMatch"
              type="number"
              {...register("gamesPerMatch", {
                required: "Home Address is required",
              })}
            />
            <FormFieldError errors={errors} field={"gamesPerMatch"} />
          </div>

          <div className="flex flex-row items-start justify-start w-full col-span-12">
            <label
              htmlFor="scheduleMatches"
              className="w-full text-sm font-medium leading-6 text-gray-900 "
            >
              <input
                className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
                id="scheduleMatches"
                type="checkbox"
                {...register("scheduleMatches", {
                  required: "scheduleMatches is required",
                })}
              />
              Schedule Matches
            </label>
          </div>

          <div className="flex flex-row items-start justify-start w-full col-span-12">
            <label
              htmlFor="carryOverResultsFromTheLastRound"
              className="w-full text-sm font-medium leading-6 text-gray-900"
            >
              <input
                className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
                id="carryOverResultsFromTheLastRound"
                type="checkbox"
                {...register(
                  "customSettings.carryOverResultsFromTheLastRound",
                  {
                    required: "carryOverResultsFromTheLastRound is required",
                  }
                )}
              />
              Carry Over Results From The Last Round
            </label>
          </div>
          {drawFormat === "roundRobin" && (
            <div className="flex flex-col items-start justify-start w-full col-span-12 gap-2">
              <h2 className="text-base font-semibold uppercase ">
                Round robin Settings
              </h2>
              <div className="flex flex-col items-start justify-start w-full col-span-12">
                <label
                  htmlFor="roundRobinSettings.idealGroupSize"
                  className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                >
                  Ideal Group Size
                </label>
                <input
                  className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
                  id="roundRobinSettings.idealGroupSize"
                  type="number"
                  {...register("roundRobinSettings.idealGroupSize", {
                    required: "idealGroupSize Per Game is required",
                  })}
                />
                <FormFieldError
                  errors={errors.roundRobinSettings}
                  field={"idealGroupSize"}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full col-span-6">
                <label
                  htmlFor="noAdvance"
                  className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                >
                  No Advance
                </label>
                <input
                  className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
                  id="noAdvance"
                  type="number"
                  {...register("roundRobinSettings.noAdvance", {
                    required: "noAdvance is required",
                  })}
                />
                <FormFieldError
                  errors={errors.roundRobinSettings}
                  field={"noAdvance"}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full col-span-12">
                <label
                  htmlFor="groupPreference"
                  className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                >
                  Group Preference
                </label>
                <select
                  className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
                  id="groupPreference"
                  {...register("roundRobinSettings.groupPreference", {
                    required: "groupPreference is required",
                  })}
                >
                  <option value="smaller">smaller</option>
                  <option value="larger">larger</option>
                </select>
                <FormFieldError
                  errors={errors.roundRobinSettings}
                  field={"drawFormat"}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full col-span-12">
                <label
                  htmlFor="seeding"
                  className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                >
                  Seeding
                </label>
                <select
                  className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
                  id="seeding"
                  {...register("roundRobinSettings.seeding", {
                    required: "seedingis required",
                  })}
                >
                  <option value="snake">Snake</option>
                  <option value="divisions">By Divisions</option>
                </select>
                <FormFieldError
                  errors={errors.roundRobinSettings}
                  field={"seeding"}
                />
              </div>
            </div>
          )}
        </div>
        <button
          type="submit"
          className="inline-flex self-end justify-end px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Make Draw
        </button>
      </div>
    </form>
  );
};

export default Draws;
