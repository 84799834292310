import React, { useEffect, useRef } from "react";
import {
  Match,
  SingleEliminationBracket,
  SVGViewer,
} from "@g-loot/react-tournament-brackets";

// import $ from "jquery";
// import "jquery-bracket/dist/jquery.bracket.min.css";
// import "jquery-bracket/dist/jquery.bracket.min.js";

import { useWindowSize } from "@uidotdev/usehooks";

const MatchTile = ({ props }) => {
  const {
    match,
    onMatchClick,
    onPartyClick,
    onMouseEnter,
    onMouseLeave,
    topParty,
    bottomParty,
    topWon,
    bottomWon,
    topHovered,
    bottomHovered,
    topText,
    bottomText,
    connectorColor,
    computedStyles,
    teamNameFallback,
    resultFallback,
  } = props;

  return (
    <div className="overflow-x-scroll"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        color: "#000",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        onMouseEnter={() => onMouseEnter(topParty.id)}
        style={{ display: "flex" }}
      >
        <div>{topParty.name || teamNameFallback}</div>
        <div>{topParty.resultText ?? resultFallback(topParty)}</div>
      </div>
      <div style={{ height: "1px", width: "100%", background: "#FF8C00" }} />
      <div
        onMouseEnter={() => onMouseEnter(bottomParty.id)}
        style={{ display: "flex" }}
      >
        <div>{bottomParty.name || teamNameFallback}</div>
        <div>{bottomParty.resultText ?? resultFallback(topParty)}</div>
      </div>
    </div>
  );
};

const matches = [
  {
    id: 19753,
    nextMatchId: null,
    tournamentRoundText: "3",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [],
  },
  {
    id: 19754,
    nextMatchId: 19753,
    tournamentRoundText: "2",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [
      {
        id: "14754a1a-932c-4992-8dec-f7f94a339960",
        resultText: null,
        isWinner: false,
        status: null,
        name: "CoKe BoYz",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19755,
    nextMatchId: 19754,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCORE_DONE",
    participants: [
      {
        id: "14754a1a-932c-4992-8dec-f7f94a339960",
        resultText: "Won",
        isWinner: true,
        status: "PLAYED",
        name: "CoKe BoYz",
        picture: "teamlogos/client_team_default_logo",
      },
      {
        id: "d16315d4-7f2d-427b-ae75-63a1ae82c0a8",
        resultText: "Lost",
        isWinner: false,
        status: "PLAYED",
        name: "Aids Team",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19756,
    nextMatchId: 19754,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCORE_DONE",
    participants: [
      {
        id: "d8b9f00a-0ffa-4527-8316-da701894768e",
        resultText: "Won",
        isWinner: true,
        status: "PLAYED",
        name: "Art of kill",
        picture: "teamlogos/client_team_default_logo",
      },
      {
        id: "d8b9f00a-0ffa-4527-8316-da701894768e",
        resultText: "Lost",
        isWinner: false,
        status: "PLAYED",
        name: "Art of Lose",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19757,
    nextMatchId: 19753,
    tournamentRoundText: "2",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [],
  },
  {
    id: 19758,
    nextMatchId: 19757,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [
      {
        id: "9397971f-4b2f-44eb-a094-722eb286c59b",
        resultText: null,
        isWinner: false,
        status: null,
        name: "Crazy Pepes",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19759,
    nextMatchId: 19757,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [
      {
        id: "42fecd89-dc83-4821-80d3-718acb50a30c",
        resultText: null,
        isWinner: false,
        status: null,
        name: "BLUEJAYS",
        picture: "teamlogos/client_team_default_logo",
      },
      {
        id: "df01fe2c-18db-4190-9f9e-aa63364128fe",
        resultText: null,
        isWinner: false,
        status: null,
        name: "Bosphorus",
        picture: "teamlogos/r7zn4gr8eajivapvjyzd",
      },
    ],
  },
];

const DrawSingleElimination = () => {
  const { width, height } = useWindowSize();

  const finalWidth = Math.max(width - 50, 500);
  const finalHeight = Math.max(height - 100, 500);

  const bracketRef = useRef(null);

  useEffect(() => {
    const data = {
      teams : [
        ["Team 1",  "Team 2" ],
        ["Team 3",  "Team 4" ],
        ["Team 5",  "Team 6" ],
        ["Team 7",  "Team 8" ],
        ["Team 9",  "Team 10"],
        ["Team 11", "Team 12"],
        ["Team 13", "Team 14"],
        ["Team 15", "Team 16"]
      ],
      results : [[ /* WINNER BRACKET */
        [[3,5], [2,4], [6,3], [2,3], [1,5], [5,3], [7,2], [1,2]],
        [[1,2], [3,4], [5,6], [7,8]],
        [[9,1], [8,2]],
        [[1,3]]
      ], [         /* LOSER BRACKET */
        [[5,1], [1,2], [3,2], [6,9]],
        [[8,2], [1,2], [6,2], [1,3]],
        [[1,2], [3,1]],
        [[3,0], [1,9]],
        [[3,2]],
        [[4,2]]
      ], [         /* FINALS */
        [[3,8], [1,2]],
        [[2,1]]
      ]]
    };

    if (bracketRef.current) {
      window.$(bracketRef.current).bracket({
        init: data,
      });
    }
  }, []);

  return <div className=" overflow-x-scroll" ref={bracketRef}></div>;
  // return (

  // <SingleEliminationBracket
  //   matches={matches}
  //   options={{
  //     style: {
  //       roundHeader: { backgroundColor: "#AAA" },
  //       connectorColor: "#FF8C00",
  //       connectorColorHighlight: "#000",
  //     },
  //   }}
  //   svgWrapper={({ children, ...props }) => (
  //     <SVGViewer
  //       background="#FFF"
  //       SVGBackground="#FFF"
  //       width={finalWidth}
  //       height={finalHeight}
  //       {...props}
  //     >
  //       {children}
  //     </SVGViewer>
  //   )}
  //   matchComponent={Match}
  // matchComponent={({
  //   match,
  //   onMatchClick,
  //   onPartyClick,
  //   onMouseEnter,
  //   onMouseLeave,
  //   topParty,
  //   bottomParty,
  //   topWon,
  //   bottomWon,
  //   topHovered,
  //   bottomHovered,
  //   topText,
  //   bottomText,
  //   connectorColor,
  //   computedStyles,
  //   teamNameFallback,
  //   resultFallback,
  // }) => {
  //   const props = {
  //     match,
  //     onMatchClick,
  //     onPartyClick,
  //     onMouseEnter,
  //     onMouseLeave,
  //     topParty,
  //     bottomParty,
  //     topWon,
  //     bottomWon,
  //     topHovered,
  //     bottomHovered,
  //     topText,
  //     bottomText,
  //     connectorColor,
  //     computedStyles,
  //     teamNameFallback,
  //     resultFallback,
  //   };
  //   return <MatchTile props={props} />;
  // }}
  // />
  // );
};

export default DrawSingleElimination;
