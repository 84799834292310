import React, { useEffect, useState } from "react";
import MatchesTab from "../../../components/sections/tabs/MatchesTab";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { classNames } from "../../../utils/classNames";
import {
  useGetQueryEventsByTournamentIdQuery,
  useGetTournamentByIdQuery,
  useGetTournamentEntriesQuery,
} from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import EventsPage from "../Events/EventsPage";
import AddPlayerForm from "../Players/AddPlayerForm";
import UserPlayersTab from "../../../components/sections/tabs/UserPlayersTab";
import loading from '../../../images/loading-image.svg'


export default function TournamentPage() {
  let { tournamentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isFetching, refetch } = useGetTournamentByIdQuery(tournamentId);
  const { tournament } = useSelector((state) => state.tournaments);
  const [playerForm, setPlayerForm] = useState(false);
  let query = "published";
  useGetQueryEventsByTournamentIdQuery({ tournamentId, query });
  const { queryEvents } = useSelector((state) => state.events);
  useGetTournamentEntriesQuery(tournamentId);
  const { tournamentEntries } = useSelector((state) => state.entries);

  const tabs = [
    {
      name: "events",
      href: "#events",
      element: (key) => <EventsPage key={key} />,
    },
    {
      name: "matches",
      href: "#matches",
      element: (key) => <MatchesTab key={key} />,
    },
    {
      name: "players",
      href: "#players",
      element: (key) => (
        <UserPlayersTab
          entries={tournamentEntries}
          tournament={tournament}
          key={key}
        />
      ),
    },
  ];

  const handlePlayerForm = () => {
    setPlayerForm(false);
  };

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab.name);
    navigate(tab.href);
  };

  useEffect(() => {
    if (!location.hash) {
      setActiveTab(tabs[0].name);
    } else {
      const currentTab = tabs.find((tab) => tab.href === location.hash);
      if (currentTab) {
        setActiveTab(currentTab.name);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (tournamentId) {
        refetch();
    }
  }, [tournamentId]);

  if (isFetching) return <div className=" h-screen w-full flex justify-center items-center"><img className="-mt-40" src={loading} alt=""/></div>;

  return (
    <>
      {playerForm ? (
        <AddPlayerForm
          handleCloseForm={handlePlayerForm}
          info={{ queryEvents, tournament }}
        />
      ) : (
        <>
          <div className="flex items-start gap-2 mt-5 mb-10">
            <div className=" px-3">
              <div className="flex items-center gap-3">
                <div className="text-lg font-bold">
                  {tournament?.tournamentName}
                </div>
                <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-blue-600 text-white rounded-sm">
                  {tournament?.status}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-2">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.startDate?.split("T")[0]} -{" "}
                </div>
                <div className="text-xs font-medium text-gray-500">
                  {" "}
                  {tournament?.endDate?.split("T")[0]}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-1">
                <div className="text-xs font-medium text-gray-500">
                  ${tournament?.tournamentFee}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-1">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.location}
                </div>
              </div>
            </div>
          </div>

          <div className="relative pb-5 border-b border-gray-200 sm:pb-0 px-3">
            {tournament?.customSettings?.enableSelfRegistrations && (
              <div className="lg:flex lg:items-center lg:justify-between">
                <div className="flex mt-3 lg:absolute lg:right-0 md:top-3 lg:mt-0">
                  <button
                    onClick={() => setPlayerForm(true)}
                    type="button"
                    className="inline-flex items-center border border-blue-700 bg-blue-700 px-1 sm:px-3 py-2 text-[11px] sm:text-sm font-semibold  text-white rounded-md hover:shadow hover:bg-blue-600"
                  >
                    Entry Form
                  </button>
                </div>
              </div>
            )}

            <div className="mt-8 sm:mt-4">
              <div className="mt-8 sm:mt-4">
                <nav className="flex -mb-px space-x-4 sm:space-x-8">
                  {tabs.map((tab) => (
                    <button
                      onClick={() => handleActiveTabChange(tab)}
                      key={tab.name}
                      className={classNames(
                        tab.name === activeTab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-4 text-xs sm:text-sm font-medium capitalize"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="px-3">
            {tabs.map((tab, index) => {
              if (tab.name === activeTab) {
                let key = tab.name + "-" + index;
                return <div key={key}>{tab.element(key)}</div>;
              }
              return null;
            })}
          </div>
        </>
      )}
    </>
  );
}
