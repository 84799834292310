import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const drawsInitialState = {
  drawName: "",
  eventId: "",
  drawId: "",
  startDate: "",
  drawsFormat: "singleElimination",
  startTime: "",
  pointsPerGame: 0,
  gamesPerMatch: 0,
  scheduleMatches: false,
  customSettings: {
    carryOverResultsFromTheLastRound: false,
  },
  roundRobinSettings: {
    useLaddrVariant: false,
    useExactNoOfGroups: false,
    enableUnratedPlayersToAdvance: false,
    idealGroupSize: 4,
    groupPreference: "smaller", // larger | smaller
    noAdvance: 0,
    seeding: "snake", // snake | divisions
  },
};

export const initialState = {
  draws: [],
  draw: {},
};

export const drawSlice = createSlice({
  name: "draw",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getAllDraws.matchFulfilled,
      (state, action) => {
        state.draws = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getDrawsById.matchFulfilled,
      (state, action) => {
        state.draw = action.payload.data[0];
      }
    );
  },
});

// Action creators are generated for each case reducer function
// export const { increment } = drawSlice.actions;

export default drawSlice.reducer;
