import React from "react";
import ScoreBoard from "../../commons/ScoreBoard";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const matches = [
  {
    eventName: "Singles",
    group: "Group 1",
    table: 32,
    apponents: [
      {
        rating: 132,
        group: "A",
        name: "Gorak, Daniel",
        losses: 2,
        wins: 1,
        matchPlyed: 2,
        location: "Finland",
        winner: true,
      },
      {
        rating: 2443,
        group: "B",
        name: "Hermann Hills",
        losses: 0,
        wins: 3,
        matchPlyed: 3,
        location: "Germany",
        winner: true,
      },
    ],
    rounds: [
      {
        A: 13,
        B: 3,
      },
      {
        A: 8,
        B: 11,
      },
      {
        A: 10,
        B: 14,
      },
      {
        A: 12,
        B: 12,
      },
      {
        A: 14,
        B: 10,
      },
      {
        A: 11,
        B: 13,
      },
    ],
  },
  {
    eventName: "Singles PPD IK",
    group: "Group 1",
    table: 32,
    apponents: [
      {
        rating: 132,
        group: "A",
        name: "Gorak, Daniel",
        losses: 2,
        wins: 1,
        matchPlyed: 2,
        location: "Finland",
        winner: true,
      },
      {
        rating: 2443,
        group: "B",
        name: "Hermann Hills",
        losses: 0,
        wins: 3,
        matchPlyed: 3,
        location: "Germany",
        winner: true,
      },
    ],
    rounds: [
      {
        A: 13,
        B: 3,
      },
      {
        A: 8,
        B: 11,
      },
      {
        A: 10,
        B: 14,
      },
      {
        A: 12,
        B: 12,
      },
      {
        A: 14,
        B: 10,
      },
      {
        A: 11,
        B: 13,
      },
    ],
  },
  {
    eventName: "Single Ks (kdksd)",
    group: "Group 1",
    table: 32,

    apponents: [
      {
        rating: 132,
        group: "A",
        name: "Gorak, Daniel",
        losses: 2,
        wins: 1,
        matchPlyed: 2,
        location: "Finland",
        winner: true,
      },
      {
        rating: 2443,
        group: "B",
        name: "Hermann Hills",
        losses: 0,
        wins: 3,
        matchPlyed: 3,
        location: "Germany",
        winner: true,
      },
    ],
    rounds: [
      {
        A: 13,
        B: 3,
      },
      {
        A: 8,
        B: 11,
      },
      {
        A: 10,
        B: 14,
      },
      {
        A: 12,
        B: 12,
      },
      {
        A: 14,
        B: 10,
      },
      {
        A: 11,
        B: 13,
      },
    ],
  },
];

const MatchesTab = () => {
  return (
    <div className="flex flex-col items-start justify-start gap-4 px-3">
      {/* Date */}
      <div className="flex flex-row items-center justify-between w-full mt-6">
        <h2 className="text-2xl font-bold text-gray-700">August 15, 2023</h2>

        <div className="flex flex-row items-center justify-between gap-2">
          <button className="w-8 h-8 p-2 bg-gray-200 rounded-md hover:bg-gray-300">
            <ChevronLeftIcon />
          </button>
          <button className="w-8 h-8 p-2 bg-gray-200 rounded-md hover:bg-gray-300">
            <ChevronRightIcon />
          </button>
        </div>
      </div>

      <div className="grid items-center justify-center w-full grid-col-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
        {matches.map((match, i) => (
          <div key={i} className="col-span-1 ">
            <ScoreBoard match={match} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatchesTab;
