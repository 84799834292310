import React, { useEffect, useState } from "react";
import MatchesTab from "../../../components/sections/tabs/MatchesTab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { classNames } from "../../../utils/classNames";
import {
  useGetEventsByTournamentIdQuery,
  useGetTournamentByIdQuery,
  useGetTournamentEntriesQuery,
} from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import EventsPage from "../Events/EventsPage";
import AddPlayerForm from "../Players/AddPlayerForm";
import { CsvImporter } from "../../../utils/playerImporterCSV";
import TablesTab from "../../../components/sections/tabs/TablesTab";
import { SquaresPlusIcon } from "@heroicons/react/24/solid";
import AddTournamentForm from "./AddTournamnetForm";
import TournamentFinanceTab from "../../../components/sections/tabs/TournamentFinanceTab";
import AdminPlayersTab from "../../../components/sections/tabs/AdminPlayersTab";
import TeamsPage from "../Teams/TeamsPage";
import loading from '../../../images/loading-image.svg'

export default function TournamentPage() {
  let { tournamentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isFetching: isFetchingTournament, refetch: refetchTournament } = useGetTournamentByIdQuery(tournamentId);
  const [showForm, setShowForm] = useState(false);
  const [playerForm, setPlayerForm] = useState(false);
  const { tournament } = useSelector((state) => state.tournaments);

  useGetEventsByTournamentIdQuery(tournamentId);
  const { events } = useSelector((state) => state.events);
  const { isFetching: isFetchingEntries, refetch: refetchEntries } = useGetTournamentEntriesQuery(tournamentId);
  const { tournamentEntries } = useSelector((state) => state.entries);
  const tabs = [
    {
      name: "events",
      href: "#events",
      element: (key) => <EventsPage key={key} />,
    },
    {
      name: "matches",
      href: "#matches",
      element: (key) => <MatchesTab key={key} />,
    },
    {
      name: "teams",
      href: "#teams",
      element: (key) => (
        <TeamsPage key={key} />
      ),
    },
    {
      name: "players",
      href: "#players",
      element: (key) => (
        <AdminPlayersTab entries={tournamentEntries} key={key} removePlayer={false} />
      ),
    },
    {
      name: "tables",
      href: "#tables",
      element: (key) => <TablesTab key={key} />,
    },
    {
      name: "finances",
      href: "#finances",
      element: (key) => <TournamentFinanceTab key={key} />,
    },
  ];

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab.name);
    navigate(tab.href);
  };
  const handlePlayerForm = () => {
    setPlayerForm(false);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    if (!location.hash) {
      setActiveTab(tabs[0].name);
    } else {
      const currentTab = tabs.find((tab) => tab.href === location.hash);
      if (currentTab) {
        setActiveTab(currentTab.name);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (tournamentId) {
      refetchTournament();  // Refetch tournament by ID
      refetchEntries();     // Refetch tournament entries
    }
  }, [tournamentId]);

  if (isFetchingTournament) return <div className=" h-screen w-full flex justify-center items-center"><img className="-mt-32" src={loading} alt=""/></div>;

  return (
    <>
      {playerForm ? (
        <AddPlayerForm
          handleCloseForm={handlePlayerForm}
          info={{ events, tournament }}
        />
      ) : showForm ? (
        <AddTournamentForm
          handleCloseForm={handleCloseForm}
          tournament={tournament}
        />
      ) : (
        <>
          <div className="flex items-start gap-2 mt-5 mb-10">
            <button onClick={() => setShowForm(true)} className="mt-1">
              <SquaresPlusIcon height={20} width={20} />
            </button>
            <div className=" px-3">
              <div className="flex items-center gap-3">
                <div className="text-lg font-bold">
                  {tournament?.tournamentName}
                </div>
                {tournament?.status === "published" ? (
                  <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-blue-600 text-white rounded-sm">
                    {tournament?.status}
                  </div>
                ) : (
                  <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-gray-400 text-white rounded-sm">
                    {tournament?.status}
                  </div>
                )}
              </div>
              <div className="flex items-center gap-1 mt-2">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.startDate?.split("T")[0]} -{" "}
                </div>
                <div className="text-xs font-medium text-gray-500">
                  {" "}
                  {tournament?.endDate?.split("T")[0]}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-1">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.location}
                </div>
              </div>
            </div>
          </div>

          <div className="relative pb-5 border-b border-gray-200 sm:pb-0 px-3">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="flex mt-3 lg:absolute lg:right-0 md:top-3 lg:mt-0">
                <button
                  onClick={() => setPlayerForm(true)}
                  type="button"
                  className="inline-flex items-center border border-black px-1 sm:px-3 py-2 text-[11px] sm:text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Add Players
                </button>
                <CsvImporter id={tournamentId} />
                <button
                  type="button"
                  className="inline-flex items-center px-1 sm:px-3 py-2 ml-3 text-[11px] sm:text-sm font-semibold text-white bg-orange-600 rounded-md shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                >
                  Export Results
                </button>
              </div>
            </div>
            <div className="mt-8 sm:mt-4">
              <div className="">
                <nav className="flex -mb-px space-x-4 sm:space-x-8">
                  {tabs.map((tab) => (
                    <button
                      onClick={() => handleActiveTabChange(tab)}
                      key={tab.name}
                      className={classNames(
                        tab.name === activeTab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-4 text-xs sm:text-sm font-medium capitalize"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div>
            {tabs.map((tab, index) => {
              if (tab.name === activeTab) {
                let key = tab.name + "-" + index;
                return (
                  <div className="px-3" key={index}>
                    {tab.element()}
                  </div>
                );
              }
            })}
          </div>
        </>
      )}
    </>
  );
}
