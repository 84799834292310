import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const playerInitialState = {
  firstName: "",
  lastName: "",
  sex: "",
  dob: "",
  homeClub: "",
  phone: "",
  email: "",
  rating: 0,
  pin:''
};

export const initialState = {
  players: [],
  player: {},
};

export const playerSlice = createSlice({
  name: "player",
  initialState: {
    tournamentPlayers: [],
    eventPlayers: [],
    playerEntries: [],
    eventEligiblePlayers: [],
    player: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getPlayersByTournamentId.matchFulfilled,
      (state, action) => {
        console.log(action.payload.data)
        state.tournamentPlayers = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getPlayersByEventId.matchFulfilled,
      (state, action) => {
        state.eventPlayers = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getEventEligiblePlayers.matchFulfilled,
      (state, action) => {
        console.log(action.payload)
        state.eventEligiblePlayers = action.payload;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getPlayerById.matchFulfilled,
      (state, action) => {
        state.player = action.payload.data[0];
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getPlayerEntries.matchFulfilled,
      (state, action) => {
        console.log(action.payload.data)
        state.playerEntries = action.payload.data;
      }
    );
  },
});


export default playerSlice.reducer;
