import React from "react";
import { NavLink } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

const routes = [
  { path: "/", breadcrumb: "Home" },
  { path: "/:tournamentId", breadcrumb: "Tournament" },
  { path: "/:tournamentId/:eventId", breadcrumb: "Event" },

  {
    path: "/admin",
    breadcrumb: "Admin > Tournaments", // No "Admin > Tournaments", just "Tournaments"
  },
  {
    path: "/profile",
    breadcrumb: "Profile", // No "Admin > Tournaments", just "Tournaments"
  },
  { path: "admin/:tournamentId", breadcrumb: "Tournament" },
  { path: "/admin/:tournamentId/:eventId", breadcrumb: "Event" },
];

const BreadCrumb = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(routes);

  return (
    <>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <NavLink
          className={` text-gray-500 ${
            index !== breadcrumbs.length - 1
              ? "after:content-['>'] after:px-2"
              : " "
          }`}
          key={match.pathname}
          to={match.pathname}
        >
          {breadcrumb}
        </NavLink>
      ))}
    </>
  );
};

export default BreadCrumb;

