import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetTournamentByIdQuery,
} from "../../../redux/features/api/apiSlice";

const UserPlayersTab = ({ entries }) => {
  const { tournamentId } = useParams();
  useGetTournamentByIdQuery(tournamentId);
  const { tournament } = useSelector((state) => state.tournaments);

  let today = new Date();
  today.setDate(today.getDate() + 1);
  let tommorow = today.toLocaleDateString("en-CA");

  return (
    <>
      { !tournament?.customSettings?.enablePlayerList &&
       tommorow < tournament?.startDate ? (
        <div className="w-full">
          <div className="mt-20 text-center text-gray-500">Player will show 1 day before tournament starts</div>
        </div>
      ) : (
        <div className="relative overflow-x-auto ">
          <div className="my-5 flex justify-between ">
            <div className="text-sm text-gray-500">
              {entries?.length} players found
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow-md sm:rounded-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
                <th scope="col" className="px-6 py-3 w-[35%]">
                  Player
                </th>
                <th scope="col" className="px-6 py-3 w-[20%]">
                  Events
                </th>
                <th scope="col" className="px-6 py-3 w-[40%]"></th>
              </tr>
            </thead>
            <tbody>
              {entries?.map((entry, i) => (
                <tr
                  key={i}
                  className="odd:bg-gray-50  even:bg-gray-100 border-b"
                >
                  <th
                    scope="row"
                    className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap "
                  >
                    <div className="bg-white rounded-xl px-3">
                      <div className="flex gap-5">
                        <div className="text-[10px] text-gray-500">
                          RATING: {entry?.player?.rating}
                        </div>
                        <div className="text-[10px] text-gray-500 font-medium">
                          {entry?.player?.sex}
                        </div>
                      </div>
                      <div className="text-lg">
                        {entry?.player?.firstName}, {entry?.player?.lastName}
                      </div>
                    </div>
                  </th>
                  <td className="px-6 py-1">
                    {" "}
                    <div className="flex flex-wrap gap-1">
                      {entry?.events?.map((event, i) => (
                        <div
                          key={i}
                          className="text-xs bg-gray-300 rounded-sm px-3"
                        >
                          {event.eventName}
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="px-6 py-1"></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default UserPlayersTab;
