import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../redux/features/api/apiSlice";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import FormFieldError from "../../components/commons/formFeildError.";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { toast } from 'react-toastify';

const Login = () => {
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
        const result = await login({ ...data }).unwrap();
        if (result.token) {
          localStorage.setItem("token", result.token);
          localStorage.setItem("user", JSON.stringify(result.data));
          dispatch(setUser(result.data));
          navigate('/')
        }
        console.log(result)
    } catch (err) {
      console.log(err);
      if (err.data && err.data.error) {
        toast.error(`${err.data.error}`);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const goBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <>
    <div className="h-screen bg-neutral-200 flex justify-center">
      <div className="container h-full p-10">
        <div className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 ">
          <div className="w-full">
            <div className="block rounded-lg bg-white shadow-lg ">
              <div className="g-0 lg:flex lg:flex-wrap">
              <div className="px-4 md:px-0 lg:w-6/12">
                  <div className="md:mx-6 md:p-12">
                  <div>
                    <button onClick={goBack}><ArrowLeftIcon width={25} height={25}/></button>
                  </div>
                    <div className="text-center">
                      <div className=" font-secondary text-2xl md:text-6xl">
                        Portal Pong
                      </div>
                      <h4 className="mb-12 mt-3 pb-1 font-light">
                        Join us to become a part on the Team.
                      </h4>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <p className="mb-4">Login your account</p>
                      <div className="grid">
                        <input
                          type="email"
                          id="email"
                          minLength={6}
                          maxLength={30}
                          placeholder="Enter Email"
                          className=" py-3 dark:bg-white bg-gray-100 border-2 border-black dark:text-black rounded px-3 text-sm"
                          {...register("email", {
                            required: "Email is required",
                          })}
                        />
                        <FormFieldError errors={errors} field={"email"} />
                        <input
                          placeholder="password"
                          type="password"
                          id="password"
                          minLength={6}
                          maxLength={30}
                          className="mt-4 py-3 dark:bg-white bg-gray-100 border-2 border-black dark:text-black rounded px-3 text-sm"
                          {...register("password", {
                            required: "Password is required",
                          })}
                        />
                        <FormFieldError errors={errors} field={"password"} />
                      </div>
                      <button
                        className="mt-4 mb-12  text-center bg-gradient-to-r from-neutral-700 via-neutral-600 to-black inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white"
                        type="submit"
                      >
                        Login
                      </button>
                    </form>
                    <FormFieldError errors={errors} field={"submitError"} />
                    <div className="flex items-center justify-between pb-6">
                      <p className="mb-0 mr-2">Don't have an Account?</p>
                      <Link
                        to="/signup"
                        className="inline-block rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger"
                      >
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex items-center bg-gradient-to-r from-neutral-900 from-10% via-neutral-800 via-30% to-neutral-900 to-90% rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none">
                  <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                    <h4 className="mb-6 text-xl font-semibold">
                      The Platform to become a Pro.
                    </h4>
                    <p className="text-sm">
                      Portal Pong is the tournament software you've been
                      waiting for. We currently support USATT and Ratings
                      Central as well as custom rating systems; round robin,
                      single elimination, and custom draws; singles, doubles,
                      and Swaythling Cup or Olympic team formats; international
                      currencies (USD, CAD, GBP, EUR); live scoring,
                      self-reported results, and printable match cards; and much
                      more. Email hello@portalpong.com today to get your free
                      personal demo.
                    </p>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Login;
