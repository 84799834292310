import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";

export const Select = ({ config, field }) => {
  const { onChange, onBlur, value, name, ref } = field;

  const loadOptions = async (inputValue) => {
    return await config.options();
  };

  return (
    <div className="flex flex-col items-start justify-start w-full gap-1">
      <label className="block col-span-4 text-sm font-medium leading-6 text-gray-900 after:content-['*']">
        {config.label}
      </label>
      <AsyncSelect
        placeholder={config.placeholder}
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        className="w-full text-sm text-gray-600 border-gray-500 rounded-sm"
        onChange={onChange}
      />
    </div>
  );
};
