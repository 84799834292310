import React, { useEffect, useState } from "react";
import { useGetEventEligiblePlayersQuery } from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import TableList from "../../commons/TableList";
import {
  CalendarDaysIcon,
  TicketIcon,
  UserIcon,
  StarIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";

const PlayerTemplate = ({ player}) => {
  return (
    <div className="flex flex-col items-start justify-start ">
      <div className="flex flex-row items-center justify-between w-full">
        <span
          className="flex flex-row items-center justify-start flex-1 text-xs font-semibold text-black uppercase"
          title="Membership No"
        >
          <TicketIcon
            width={16}
            height={16}
            className="inline-block mr-2 text-gray-600"
          />{" "}
          {player.memberShip}
        </span>
        <span
          className="flex flex-row items-center justify-start text-xs font-semibold text-black uppercase w-max"
          title="Membership Expiry Date"
        >
          <CalendarDaysIcon
            width={16}
            height={16}
            className="inline-block mr-2 text-gray-600"
          />{" "}
          {player.memberShipExpiryDate}
        </span>
      </div>
      <div className="w-full ">
        <span className="flex flex-row items-center justify-start text-sm font-semibold">
          <UserIcon
            width={16}
            height={16}
            className="inline-block mr-2 text-gray-600"
          />
          {player.firstName} &nbsp; {player.lastName} - ( {player.sex})
        </span>
      </div>
      <div className="flex flex-row items-center justify-start w-full gap-2">
        {player.homeClub && (
          <span className="flex text-xs">{player.homeClub}</span>
        )}
        {player.rating && (
          <span className="flex flex-row items-center justify-start text-xs">
            <StarIcon
              width={16}
              height={16}
              className="inline-block mr-1 text-gray-600"
            />
            {player.rating}
          </span>
        )}
        {player.homeAddress && (
          <span className="block text-xs">
            <MapPinIcon
              width={16}
              height={16}
              className="inline-block mr-1 text-gray-600"
            />
            {player.homeAddress}
          </span>
        )}
      </div>
    </div>
  );
};

const ManagePlayers = () => {
  let { eventId } = useParams();
  const { isFetching } = useGetEventEligiblePlayersQuery(eventId);
  const {eventEligiblePlayers} = useSelector((state) => state.players) || [];

  const columns = React.useMemo(
    () => [
      {
        Header: "Player",
        accessor: "",
        Cell: ({ row, i }) => {
          return <PlayerTemplate key={i} player={row.original} />; // or any other data you want to render in the cell
        },
      },
    ],
    []
  );

  if(isFetching) return "Loading .."

  if (!eventEligiblePlayers) return;

  return <TableList columns={columns} data={eventEligiblePlayers} />;
};

export default ManagePlayers;
