import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetQueryEventsByTournamentIdQuery,
} from "../../../redux/features/api/apiSlice";

import {
  Squares2X2Icon,
  Bars2Icon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { EventCard } from "../../../components";
import loading from '../../../images/loading-image.svg'


const EventsPage = () => {
  let { tournamentId } = useParams();
  let query = 'published'
  const { isFetching } = useGetQueryEventsByTournamentIdQuery({tournamentId, query});
  const { queryEvents } = useSelector((state) => state.events);
  const [activeTab, setActiveTab] = useState("active");
  const [dataView, setDataView] = useState("grid"); //! grid | tabel


  return (
    <>
        <div className="min-h-screen ">
          {/* toolbar */}
          <div className="flex flex-row items-center justify-start w-full gap-2 py-4">
            {/* Search bar */}
            <div className="flex flex-row items-center justify-between flex-1 px-2 sm:px-4 py-1 sm:py-2 bg-white border rounded-md">
              <MagnifyingGlassIcon className="w-5 h-5" />
              <input
                type="search"
                className="px-1 bg-transparent border-none outline-none focus:outline-none"
              />
            </div>

            {/* data view */}
            <div className="hidden  sm:block">
              <button
                onClick={() =>
                  setDataView(dataView === "grid" ? "tabel" : "grid")
                }
                className={`px-4 py-2  uppercase rounded-s-md ${
                  dataView === "grid"
                    ? "bg-black text-white font-medium"
                    : "bg-white text-black"
                }`}
              >
                <Squares2X2Icon className="w-5 h-5" />
              </button>
              <button
                onClick={() =>
                  setDataView(dataView === "tabel" ? "grid" : "tabel")
                }
                className={`px-4 py-2  uppercase rounded-e-md ${
                  dataView === "tabel"
                    ? "bg-black text-white font-medium"
                    : "bg-white text-black"
                }`}
              >
                <Bars2Icon className="w-5 h-5 " />
              </button>
            </div>
          </div>
          {isFetching ? (
            <div className=" h-[500px] w-full flex justify-center items-center"><img className="-mt-32" src={loading} alt=""/></div>
        ) : queryEvents?.length < 1 ? (
          <div className="text-center mt-20 font-medium text-gray-500">
            No Events Available
          </div>
        ) : (
          <div
            className={`grid gap-4 ${
              activeTab === "grid" ? "grid-cols-4" : "grid-cols-12"
            }`}
          >
            {queryEvents.map((event, index) => {
              return (
                <div
                  key={index}
                  className={`p-6 bg-white rounded-md shadow border ${
                    dataView === "grid" ? "col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3" : "col-span-12"
                  }`}
                >
                  <EventCard event={event}/>
                </div>
              );
            })}
          </div>
        )}
        </div>
    </>
  );
};

export default EventsPage;