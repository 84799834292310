import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";


export const initialState = {
  finances:{},
  eventEntries: [],
  tournamentEntries:[],
};

export const entrySlice = createSlice({
  name: "entry",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getTournamentFinances.matchFulfilled,
      (state, action) => {
        state.finances = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getEventEntries.matchFulfilled,
      (state, action) => {
        state.eventEntries = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getTournamentEntries.matchFulfilled,
      (state, action) => {
        state.tournamentEntries = action.payload.data;
      }
    );
  },
});

export default entrySlice.reducer;
